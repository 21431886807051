<template>
    <b-sidebar
      id="add-new-user-sidebar"
      :visible="isAddNewUserSidebarActive"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      backdrop
      no-header
      right
      @hidden="resetForm"
      @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            Drag Files To Upload Library
          </h5>

         
  
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
  
        </div>
      
   <div  class="p-2"   >
     <h5>Select Categories </h5>
<department-picker :initialCategories="selectedCategories" @selectionChanged="onDepartmentSelectionChanged"  ></department-picker>
 </div>

   <div  class="p-2"  v-if="selectedCategories.length==0 " >
            <br/>
    No category is selected. Assets you add will be uploaded to public folder.
    <br/>
        </div> 
 
        <div class="p-2" v-if="selectedCategories.length!=0 " >
         
     
  
    Asset you will upload will be added to {{selectedCategories.length}} categories.
    <br/>
        </div> 

        <Attachment  @refetch-data="refetchData" :currentCategory="selectedCategories" />
      </template>
    </b-sidebar>
  </template>
  
  <script>
  import {
    BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,BCardText,  BCard,
     
      BMedia,
      BMediaAside,
      BMediaBody,
  } from 'bootstrap-vue'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { ref } from '@vue/composition-api'
  import { required, alphaNum, email } from '@validations'
  import formValidation from '@core/comp-functions/forms/form-validation'
  import Ripple from 'vue-ripple-directive'
  import vSelect from 'vue-select'
  import countries from '@/@fake-db/data/other/countries'
  import store from '@/store'
  import DepartmentPicker from '../components/BlogCategoryPicker.vue'
  import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
  import Attachment from "./Attachment.vue";
  
  export default {
    components: {
      
      BMedia,
      BMediaAside,
      BMediaBody,
      BSidebar,
      BForm,
      BFormGroup,
      BFormInput,
      BFormInvalidFeedback,
      BButton,
      vSelect,
      BCardText,
      Attachment,
      // Form Validation
      ValidationProvider,
      ValidationObserver,
      DepartmentPicker,
       
    },
    computed:{
        activeCategory () {
            return this.categorydata;
        }
    },
    directives: {
      Ripple,
    },
    model: {
      prop: 'isAddNewUserSidebarActive',
      event: 'update:is-add-new-user-sidebar-active',
    },
    props: {
      isAddNewUserSidebarActive: {
        type: Boolean,
        required: true,
      },
      roleOptions: {
        type: Array,
        required: true,
      },
      planOptions: {
        type: Array,
        required: true,
      },
      category :null
        
       
      ,
    },
    methods:{
       

        refetchData(){
            //console.log("Refecth in Uploader");
            this.$emit('refetch-data')
          
        },
     
  
      uploadFile(paylod) {
        this.optionsLocal = JSON.parse(JSON.stringify(this.generalData.data));
      },
  
      fileSelected(payload) {
        const formData = new FormData();
        formData.append("file", payload);
        formData.append("courseId", this.courseId);
        formData.append("acc", 3);
        formData.append("platform", "webadmin");
        this.isLoading = true;
  
        axios
          .post("/upload/admin", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            //console.log(response);
            this.optionsLocal.featuredImage = response.data.url;
            this.isUpdated = true;
            this.isLoading = false;
            this.$bvToast.toast(`${response.data.message}`, {
              title: "Success",
              autoHideDelay: 5000,
              appendToast: true,
              variant: "success",
              solid: true,
            });
          })
          .catch((error) => {
            //console.log(error);
            this.isLoading = false;
            this.$bvToast.toast(`${error.response.data.message}`, {
              title: "Error",
              autoHideDelay: 5000,
              appendToast: true,
              variant: "danger",
              solid: true,
            });
          });
      },
  
      onDepartmentSelectionChanged(payload)
      {
        //console.log("Department selected");
        //console.log(payload);
        this.selectedCategories=payload;
   
      },
       selectionChanged(selectedGroup){
         // //console.log(selectedGroup);
  
         if (selectedGroup==null||selectedGroup==undefined)
         {
     this.currentCategory.upperCategory = null;
         }else
         {
     this.currentCategory.upperCategory = selectedGroup.id
         }
     
      },
      deletecategory(){
        this.currentCategory.deleted=true;
  
        this.$store.dispatch('apps-departments/createDepartment', this.currentCategory)
          .then((result) => {
            //console.log(result);
            
            this.$emit('refetch-data')
              this.$emit('update:is-add-new-user-sidebar-active', false)
          });
      },
  
      submitForm(){
   
      //console.log(this.currentCategory);
      
         this.$store.dispatch('apps-departments/createDepartment', this.currentCategory)
          .then((result) => {
            //console.log(result);
            
            this.$emit('refetch-data')
              this.$emit('update:is-add-new-user-sidebar-active', false)
          });
      }
    },
    
    watch: {
       categorydata:   {
          handler:function (n, o)   {
            //console.log('new client: ', n);
  
                    if (n==null||n==undefined)
                    {
                      this.currentCategory={
                        id:null,
                        name:null,
                        description:null,
                         upperCategory: null,
                         featuredImage:null,
                        Parent:null
                      }
                    } else
                    { 
                      //console.log('new client: ', n.selectedCategory);
                    this.currentCategory = n.selectedCategory;
                    }
          
  
          },
          deep: true
      }
      
  },
    
    data() {
      return {
        selectedCategories:[],
         
        required,
        alphaNum,
        email,
        countries,
        currentCategory :null,
        place: null, // optional,
        localOptions: {},
        isLoading: false,
        file: null,
        isUpdated: false,
        optionsLocal: {},
        profileFile: null,
      }
    },
     inject: ['categorydata'],
    setup(props, { emit }) {
      const blankUserData = {
        name: '',
        upperCategory: '',
        featuredImage:null,
        description: '',
        parent:null
         
      }
      const refInputEl = ref(null);
      const previewEl = ref(null);
  
      const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl);
   
  
      const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
      const resetuserData = () => {
        userData.value = JSON.parse(JSON.stringify(blankUserData))
      }
  
      const onSubmit = () => {
        store.dispatch('apps-departments/createDepartment', userData.value)
          .then(() => {
            emit('refetch-data')
            emit('update:is-add-new-user-sidebar-active', false)
          })
      }
  
      const {
        refFormObserver,
        getValidationState,
        resetForm,
      } = formValidation(resetuserData)
  
      return {
        userData,
        onSubmit,
  
        refFormObserver,
        getValidationState,
        resetForm,
        refInputEl,
        previewEl,
        inputImageRenderer,
      }
    },
  }
  </script>
  
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  
  #add-new-user-sidebar {
    .vs__dropdown-menu {
      max-height: 200px !important;
    }
  }
  </style>
  